import React from "react";
import { GridCloseIcon, GridColDef } from "@mui/x-data-grid-pro-v5";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
};

export const getAirbusSpecificationTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Airbus specification",
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash Ref",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "scope_limitation",
      headerName: "Scope/Limitation",
      editable: true,
      renderHeader: (params) => <HeaderCell {...params} required={true} />,
      renderCell: (params) => (
        <EditableTemplateCell {...params} placeholderText="Write in plain text..." />
      ),
      headerAlign: "left" as const,
    },
    {
      ...defaultColumnConfig,
      field: "removeRowButton",
      headerName: " ",
      align: "center",
      renderCell: (params) => <IconButtonCell {...params} Icon={GridCloseIcon} />,
      flex: 0.1,
    },
  ];

  return columns;
};
