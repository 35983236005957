import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel, InfoTitle } from "../../form-field-label/form-field-label";
import { BufferStockStrategyFormTable } from "../../table/buffer-stock-strategy-form-table/buffer-stock-strategy-form-table";
import { TableLinesBox } from "../../table-lines-box/table-lines-box";
import {
  BufferStockStrategyFormData,
  PaginationSizeForFormTable,
  PartialStep,
  QuestionnaireForms,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectSpecsOfBufferStockStrategy,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./buffer-stock-strategy-form.styled";

export const BufferStockStrategyForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const tableData = useSelector(selectSpecsOfBufferStockStrategy);
  const [page, setPage] = useState(1);
  const formData = useSelector(selectFormData) as BufferStockStrategyFormData;
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const { handleSubmit, control, getValues } = useForm<BufferStockStrategyFormData>({
    defaultValues: formData,
  });

  useEffect(() => {
    if (formData) {
      dispatch(
        vendorManagementActions.fetchSpecsOfBufferStockStrategyRequest({
          companyId: companyId,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          filter_completed: hideCompletedTableLines,
        })
      );
    }
  }, [page, hideCompletedTableLines]);

  const saveChanges = (isForCompletion: boolean) => {
    const additional_comment = getValues("additional_comment");
    dispatch(
      vendorManagementActions.saveAdditionalCommentFieldOfFormRequest({
        companyId,
        additionalComment: additional_comment,
        stepName: QuestionnaireForms.BufferStockStrategy,
      })
    );

    const fieldOfStep = isForCompletion ? "is_completed" : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true, activeFormStepIndex);
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<BufferStockStrategyFormData> = (data) => {
    const isAllLinesCompleted = tableData?.results?.uncompleted_count === 0;
    if (isAllLinesCompleted) {
      makeFormStepCompletionProcess();
    } else {
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    } else if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    }
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle title={"Buffer stock strategy"} isCompleted={isFormCompleted} />

          <FormFieldBox>
            <FormFieldLabel label="Buffer stock Strategy : Stock Management from B to A per spec" />

            <TableLinesBox
              completedCount={tableData?.results?.completed_count}
              totalCount={tableData?.results?.total_count}
              hideCompletedTableLines={hideCompletedTableLines}
              setHideCompletedTableLines={setHideCompletedTableLines}
              setPage={setPage}
            />
            <InfoTitle />

            <BufferStockStrategyFormTable
              hideCompletedTableLines={hideCompletedTableLines}
              page={page}
              pageSize={PaginationSizeForFormTable}
              setPage={setPage}
            />
          </FormFieldBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={formData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};
