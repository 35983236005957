import { PaginationAndFilterForSpecification } from "../redux";

export const generateSearchAndFilterSpecQuery = (
  params: PaginationAndFilterForSpecification
): string => {
  const { page, pageSize, search, specType, isPomDistributor, stepId, filter_completed } = params;
  let query = `page=${page}&pageSize=${pageSize}`;

  if (search) {
    query += `&search=${encodeURIComponent(search)}`;
  }

  if (specType) {
    if (Array.isArray(specType)) {
      specType.forEach((type) => {
        query += `&specType=${encodeURIComponent(type)}`;
      });
    } else {
      query += `&specType=${encodeURIComponent(specType)}`;
    }
  }

  if (isPomDistributor) {
    query += `&questionnaire_type=pom_distributors`;
  }

  if (filter_completed) {
    query += `&filter_completed=${encodeURIComponent(filter_completed)}`;
  }

  if (stepId) {
    query += `&step_id=${encodeURIComponent(stepId)}`;
  }

  return query;
};
