import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { IdentificationForm } from "../forms/identification-form/identification-form";
import { LeadTimeForImplementationForm } from "../forms/lead-time-for-implementation-form/lead-time-for-implementation-form";
import { OnboardingForm } from "../forms/onboarding-form/onboarding-form";
import { ScopeForm } from "../forms/scope-form/scope-form";
import { LeadTimeForProductionForm } from "../forms/lead-time-for-production-form/lead-time-for-production-form";
import { ApprovalForm } from "../forms/approval-form/approval-form";
import { ErrorScreen } from "../error-screen/error-screen";
import { LoadingScreen } from "../loading-screen/loading-screen";
import { ScreenBanner } from "../screen-banner/screen-banner";
import {
  selectActiveFormStepIndex,
  selectFormDataError,
  selectFormDataLoading,
  selectSteps,
  selectVisibleSteps,
} from "../../redux/selectors";
import {
  PomManufacturerFormKeyMapping,
  PomManufacturerForms,
  vendorManagementActions,
} from "../../redux";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";

const availableForms = {
  Identification: IdentificationForm,
  Approval: ApprovalForm,
  Onboarding: OnboardingForm,
  Scope: ScopeForm,
  "Lead time for implementation": LeadTimeForImplementationForm,
  "Lead time for production": LeadTimeForProductionForm,
};

export const PomManufacturerQuestionnaire: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const requestFormDataLoading = useSelector(selectFormDataLoading);
  const requestFormDataError = useSelector(selectFormDataError);
  const steps = useSelector(selectSteps);
  const activeFormStepIndexState = useSelector(selectActiveFormStepIndex); // useEffect not working with static index number, need to use this state to trigger useEffect
  const visibleSteps = useSelector(selectVisibleSteps);
  const activeFormStepIndexFromSteps = getActiveStepIndex(visibleSteps);

  const { forms, formNames } = useMemo(() => {
    const visibleForms: React.ComponentType<any>[] = [];
    const visibleFormNames: PomManufacturerForms[] = [];

    Object.entries(PomManufacturerFormKeyMapping).forEach(([enumKey, formKey]) => {
      const step = steps.find((step) => step.step_name === formKey);
      if (step?.is_visible) {
        visibleForms.push(availableForms[formKey]);
        visibleFormNames.push(PomManufacturerForms[enumKey as keyof typeof PomManufacturerForms]);
      }
    });

    return { forms: visibleForms, formNames: visibleFormNames };
  }, [steps]);
  const ActiveFormComponent = forms[activeFormStepIndexFromSteps];

  useEffect(() => {
    if (companyId && activeFormStepIndexFromSteps !== -1) {
      dispatch(
        vendorManagementActions.fetchFormDataRequest({
          formName: formNames[activeFormStepIndexFromSteps],
          companyId: companyId,
        })
      );
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
    }
  }, [activeFormStepIndexState]);

  if (requestFormDataError) {
    return <ErrorScreen message={requestFormDataError.message} />;
  }

  return (
    <>
      {requestFormDataLoading ? (
        <LoadingScreen />
      ) : ActiveFormComponent ? (
        isSmallScreen ? (
          <>
            <ScreenBanner />
            <ActiveFormComponent />
          </>
        ) : (
          <ActiveFormComponent />
        )
      ) : null}
    </>
  );
};
