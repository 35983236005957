import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormAddButton } from "../../../form-add-button/form-add-button";
import { InternationalStandardTable } from "../../../table/international-standard-table/international-standard-table";
import {
  selectIsFormCompleted,
  selectProcessControlStandardsLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  InternationalStandardTableRow,
  PartialStep,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./add-international-standard-field.styled";

const templateRow: InternationalStandardTableRow = {
  name: "",
  dash_ref: "",
  revision: "",
  controlled_process: "",
  description: "",
  scope_limitation: "",
};

export const AddInternationalStandardField: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLoading = useSelector(selectProcessControlStandardsLoading);

  useEffect(() => {
    dispatch(vendorManagementActions.fetchProcessControlStandardsRequest(companyId));
  }, []);

  const handleClickAddStandardBtn = () => {
    dispatch(
      vendorManagementActions.addProcessControlStandardRequest({
        companyId: companyId,
        standard: templateRow,
      })
    );
    checkInputChange();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  return (
    <S.StyledBox>
      <InternationalStandardTable />

      <S.StyledBtnBox>
        <FormAddButton
          btnText={"Add international standard"}
          onClick={handleClickAddStandardBtn}
          disabled={isLoading}
        />
      </S.StyledBtnBox>
    </S.StyledBox>
  );
};
