import React, { useCallback } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
} from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PartialStep, vendorManagementActions } from "@next/modules/vendor-management/redux";
import {
  selectIsFormCompleted,
  selectRemoveSpecFromScopeLoading,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getAirbusSpecificationTableColumns } from "./airbus-specification-table.columns";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./airbus-specification-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
};

export const AirbusSpecificationTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  setPage,
  pageSize,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const tableData = useSelector(selectSpecsOfScope);
  const rows = tableData?.results?.data;
  const isLoadingFetchTableRows = useSelector(selectSpecsOfScopeLoading);
  const isLoadingDeleteProcessControlSpec = useSelector(selectRemoveSpecFromScopeLoading);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const columns = getAirbusSpecificationTableColumns();
  const specTypesForTableSpecs = ["A2ERD", "A2TS"];

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      if (params.field === "removeRowButton") {
        const id = params.row.specification_id;

        dispatch(
          vendorManagementActions.removeSpecFromScopeRequest({
            specification_id: id,
            companyId: companyId,
            onSuccess: onUpdateSuccess,
          })
        );
      }
      if (!params.colDef.editable) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [isFormCompleted, rows]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const specification_id = newRow?.specification_id;
    let updatedField = null;
    let updatedFieldKey = null;
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        updatedField = { [key]: newRow[key] };
        updatedFieldKey = [key];
      }
    });

    if (updatedField) {
      dispatch(
        vendorManagementActions.saveSpecOfScopeRequest({
          specificationId: specification_id,
          spec: updatedField,
          companyId: companyId,
          onSuccess: onUpdateSuccess,
        })
      );
    }
    return newRow;
  };

  const onUpdateSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        specType: specTypesForTableSpecs,
        pagination: { page: page, pageSize: pageSize },
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStep = (key: "is_completed" | "is_partially_filled", value: boolean) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={32}
        pagination
        paginationMode="server"
        page={page - 1}
        pageSize={pageSize}
        rowCount={tableData?.count ? tableData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        autoHeight
        disableSelectionOnClick
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        loading={isLoadingFetchTableRows || isLoadingDeleteProcessControlSpec}
        getRowId={(row) => `${row.name}-${row.specification_id}`}
      />
    </>
  );
};
